import React, { useContext, useState } from 'react'

import { Background, Row, Column } from '@stokr/components-library'

import backgroundImage from 'static/images/covers/rocket.png'

import {
  RegisterTaxId,
  AuthProvider,
  AuthContext,
} from '@stokr/components-library'
import redirectUrl from 'utils/redirect-url'
import deleteRedirectCookieAndNavigate from 'utils/delete-redirect-cookie-and-navigate'
import { DisplayOnBreakpoint } from 'styles/rwd'

const RegisterTaxIdMain = ({ setIsModalOpen, setShowComplete }) => {
  const { user } = useContext(AuthContext)
  return (
    <Row main>
      <Column part={8} mainFixed>
        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <Background src={backgroundImage} mobileRatio="16:9" />
        </DisplayOnBreakpoint>
      </Column>

      <Column part={8} mainRelative>
        <RegisterTaxId
          setShowComplete={setShowComplete}
          showSkipButton
          showBackButton={redirectUrl ? false : true}
          redirectUrl={redirectUrl}
          onContinueClick={deleteRedirectCookieAndNavigate}
        />
      </Column>
    </Row>
  )
}

export default RegisterTaxIdMain
