import React, { useContext, useEffect } from 'react'
import { AuthContext } from '@stokr/components-library'
import { useCustomNav } from 'utils/customNavigate'

const ActivateUser = () => {
  const { user } = useContext(AuthContext)
  const { country } = user
  const navigator = useCustomNav()

  useEffect(() => {
    if (country) {
      navigator.navigate('/verify-identity')
    } else {
      navigator.navigate('/welcome')
    }
  }, [])

  return <div />
}

export default ActivateUser
