import React, { useState, useContext, useEffect } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  ComponentWrapper,
  AuthContext,
} from '@stokr/components-library'
import Input from 'components/Input/Input'
import Form, { FormField, FormError } from 'components/Form/Form'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import { Modal, ModalInner } from 'components/Modal/Modal'
import { DisplayOnBreakpoint } from 'styles/rwd'
import Text from 'components/Text/Text.styles'

const RegisterEntity = () => {
  const { user, updateUser, sendWelcomeEmail } = useContext(AuthContext)
  const { country, entity_name } = user
  const navigator = useCustomNav()

  const [isUpdatingUser, setIsUpdatingUser] = useState(false)
  const [error, setError] = useState('')

  const initialValues = {
    entityName: '',
  }

  const validationSchema = Yup.object().shape({
    entityName: Yup.string().required('Oops, this can‘t be blank'),
  })

  useEffect(() => {
    if (country) {
      navigator.navigate('/verify-identity')
    } else if (entity_name) {
      navigator.navigate('/country-of-residence')
    }
  }, [])

  const handleOnSubmit = async (e) => {
    setIsUpdatingUser(true)

    try {
      await updateUser({
        entity_name: e.entityName,
        userType: 'investor_entity',
      })

      await sendWelcomeEmail() // Doesn't throw error if it fails
      navigator.navigate('/country-of-residence')
    } catch (error) {
      setError('Something went wrong. Please try again.')
      setIsUpdatingUser(false)
    }
  }

  return (
    <Layout signupFlow noFooter>
      <SEO title="Register Entity" />
      <Modal
        isRegisterEntity
        fullscreen
        isOpen
        onClose={() => window.history.back()}
      >
        <DisplayOnBreakpoint fullscreen down breakpoint="Medium">
          <ModalInner center>
            <Text center>
              <p>Currently not available on mobile</p>
            </Text>
          </ModalInner>
        </DisplayOnBreakpoint>

        <DisplayOnBreakpoint fullscreen up breakpoint="Medium">
          <ModalInner>
            <ComponentWrapper noPaddingHorizontal>
              <Text>
                <h3>REGISTER A LEGAL ENTITY</h3>
                <br />
                <p>
                  You will be contacted by our compliance team dedicated to
                  legal entities. You can still continue setting up your
                  account.
                </p>
              </Text>
            </ComponentWrapper>

            <ComponentWrapper noPaddingHorizontal>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
              >
                {({ values, errors, touched, handleChange, handleBlur }) => {
                  const submitDisabled = !values.entityName

                  return (
                    <Form>
                      <ComponentWrapper noPadding>
                        <FormField>
                          <Input
                            id="entity-name"
                            name="entityName"
                            type="input"
                            label="What's the name of your legal entity?"
                            value={values.entityName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.entityName}
                            touched={!!touched.entityName}
                          />
                          <FormError
                            show={errors.entityName && touched.entityName}
                          >
                            {errors.entityName}
                          </FormError>
                        </FormField>
                      </ComponentWrapper>
                      <br />
                      <ComponentWrapper
                        noPaddingBottom
                        noPaddingHorizontal
                        center
                      >
                        <Button
                          type="submit"
                          disabled={submitDisabled || isUpdatingUser}
                        >
                          CONTINUE SET-UP
                        </Button>

                        <FormError withTopSpacing show={error}>
                          {error}
                        </FormError>
                      </ComponentWrapper>
                    </Form>
                  )
                }}
              </Formik>
            </ComponentWrapper>
          </ModalInner>
        </DisplayOnBreakpoint>
      </Modal>
    </Layout>
  )
}

export default RegisterEntity
