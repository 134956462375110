import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '@stokr/components-library'
import { useCustomNav } from 'utils/customNavigate'
import VerifyIdentity from './verify-identity'
import VerifyIdentityComplete from './complete'
import Pending from './pending'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'

const VerifyIdentityPage = () => {
  const { user } = useContext(AuthContext)
  const { kyc_status, emailVerified } = user
  const navigator = useCustomNav()

  const [showPending, setShowPending] = useState(false)

  useEffect(() => {
    if (!emailVerified) {
      navigator.navigate('/resend-activation-email')
    }
  }, [])

  const renderPage = () => {
    const isKYCComplete = kyc_status === 'Accepted'

    const showVerifyIndentityPage =
      !kyc_status ||
      kyc_status === 'Draft' ||
      kyc_status === 'Reapply' ||
      kyc_status === 'Cancelled'

    //we need to catch the case when the user is in the flow and just finished verify identity
    //on complete we redirect to pending page
    const showPendingPage =
      showPending || (!isKYCComplete && !showVerifyIndentityPage)

    if (isKYCComplete) {
      return <VerifyIdentityComplete isKYCComplete={isKYCComplete} />
    }
    if (showVerifyIndentityPage) {
      return <VerifyIdentity setShowPending={setShowPending} />
    }

    if (showPendingPage) {
      return (
        <Pending
          isKYCComplete={isKYCComplete}
          setShowPending={setShowPending}
          kycStatus={kyc_status}
        />
      )
    }
  }

  return (
    <Layout signupFlow progress>
      <SEO title="Verify Identity" />
      {renderPage()}
    </Layout>
  )
}

export default VerifyIdentityPage
