import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@stokr/components-library'
import { useCustomNav } from 'utils/customNavigate'
import CountryOfResidence from './country-of-residence'
import CountryNotAllowed from './country-not-allowed'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'

export const CountryOfResidencePage = () => {
  const { user } = useContext(AuthContext)
  const { emailVerified } = user
  const navigator = useCustomNav()

  const [selectedCountry, setSelectedCountry] = useState('')
  const [isCountryNotAllowed, setIsCountryNotAllowed] = useState(false)

  useEffect(() => {
    if (!emailVerified) {
      navigator.navigate('/resend-activation-email')
    }
  }, [])

  const renderPage = () => {
    if (isCountryNotAllowed) {
      return (
        <CountryNotAllowed
          setIsCountryNotAllowed={setIsCountryNotAllowed}
          selectedCountry={selectedCountry}
        />
      )
    }

    return (
      <CountryOfResidence
        setIsCountryNotAllowed={setIsCountryNotAllowed}
        setSelectedCountry={setSelectedCountry}
      />
    )
  }

  return (
    <Layout signupFlow progress>
      <SEO title="Country of Residence" />
      {renderPage()}
    </Layout>
  )
}

export default CountryOfResidencePage
